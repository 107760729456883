import i18n from "@/core/plugins/vue-i18n";

const selection_options = [
	{
		id: null,
		name: i18n.t("COMMON.SELECTION_OPTION_TITLE")
	}
];

const address_options = [
	{
		id: null,
		address_description: i18n.t("COMMON.SELECTION_OPTION_TITLE")
	}
];

const selection_all = [
	{
		id: null,
		name: i18n.t("COMMON.SELECTION_ALL_TITLE")
	}
];

const selection_others = [
	{
		id: 0,
		name: i18n.t("COMMON.SELECTION_OTHERS_TITLE")
	}
];

const selectOption = function (data, custom_option) {
	let default_option = {
		with_select_option: true, //change to [false] will not return select option
		id_field_name: "id", //change id field name
		id_field_value: null, //change id field value
		text_field_name: "name", //change text field name
		text_field_value: i18n.t("COMMON.SELECTION_OPTION_TITLE"), //change text field value
		with_others_option: false, //change to [true] will return others option at last index
		others_id_field_value: 0, //change others option id field value
		others_text_field_value: i18n.t("COMMON.SELECTION_OTHERS_TITLE"), //change others option text field value
		other_option_upper: false,
		text_field_value_all: false
	};
	let option = Object.assign({}, default_option, custom_option);

	if (option.text_field_value_all == true) {
		option.with_select_option = true;
		option.text_field_value = i18n.t("COMMON.TXT_ALL");
	}

	let result = [];

	if (option.with_select_option === true) {
		let select_option_data = {};

		select_option_data[option.id_field_name] = option.id_field_value;
		select_option_data[option.text_field_name] = option.text_field_value;

		result = result.concat(select_option_data);
	}

	result = result.concat(data);

	if (option.with_others_option === true) {
		let others_option_data = {};

		others_option_data[option.id_field_name] = option.others_id_field_value;
		others_option_data[option.text_field_name] = option.other_option_upper ? option.others_text_field_value.toUpperCase() : option.others_text_field_value;

		result = result.concat(others_option_data);
	}

	return result;
};

const UnixTimeToDateTime = function (unixtimestamp) {
	if (unixtimestamp == null) {
		return "";
	}
	//Convert timestamp to milliseconds
	var date = new Date(unixtimestamp);

	// Year
	var year = date.getFullYear();

	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + date.getMonth() : date.getMonth() + 1;

	// Day
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	// Hours
	var hours = date.getHours();

	// Minutes
	var minutes = "0" + date.getMinutes();

	// Seconds
	var seconds = "0" + date.getSeconds();

	// Display date time in MM-dd-yyyy h:m:s format
	var datetime = day + "/" + month + "/" + year + " " + hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
	return datetime;
};

const UnixTimeToDate = function (timstamp) {
	if (timstamp == null) {
		return "";
	}

	//Convert timestamp to milliseconds
	var date = new Date(timstamp);

	// Year
	var year = date.getFullYear();

	// Month
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

	// Day
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	// Display date time in MM-dd-yyyy h:m:s format
	var finalDate = year + "-" + month + "-" + day;

	return finalDate;
};

const getTodayDate = function () {
	let currentDate = new Date(),
		month = "" + (currentDate.getMonth() + 1),
		day = "" + currentDate.getDate(),
		year = currentDate.getFullYear();

	if (month.length < 2) {
		month = "0" + month;
	}

	if (day.length < 2) {
		day = "0" + day;
	}

	return [year, month, day].join("-");
};

const recordPerPage = 20;

const resetObjServer = function (obj) {
	try {
		return JSON.parse(JSON.stringify(obj));
	} catch (err) {
		// 👇️ This runs
		console.log("Error: ", err.message);
	}

	return {};
};

const numberFormatCurrency = function (number) {
	if (number == null) number = 0;
	if (number == null || isNaN(parseFloat(number))) {
		number = 0;
	}

	if (/,/.test(number)) {
		number = number.replaceAll(",", "");
	}

	return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(parseFloat(number).toFixed(2));
};

const validateEmail = function (email) {
	if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
		return true;
	} else {
		return false;
	}
};

const validateContactNo = function (calling_code, contact_no) {
	let full_contact = `${calling_code}${contact_no}`;
	var regex = /^\+(?:[0-9]?){6,14}[0-9]$/;
	if (calling_code == "+60") {
		regex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$|^(\+60)[1-9]{1,2}-*[0-9]{6,8}$/;
	}

	if (regex.test(full_contact)) {
		return true;
	} else {
		return false;
	}
};

const validateCapitalAlphaNumeric = function (string) {
	return /^[A-Z0-9]+$/i.test(string);
};

const ucword = function (value) {
	if (value == null) return null;
	value = value.toLowerCase();
	if (/\n/.test(value)) {
		return value
			.split("\n")
			.map((val1) =>
				val1
					.split(" ")
					.map((val) => val.charAt(0).toUpperCase() + val.slice(1))
					.join(" ")
			)
			.join("\n");
	} else {
		return value
			.split(" ")
			.map((val) => val.charAt(0).toUpperCase() + val.slice(1))
			.join(" ");
	}
};

const numberFormatInteger = function (number) {
	if (number == null || number.toString().trim() == "" || isNaN(parseFloat(number))) {
		number = 0;
	}

	return new Intl.NumberFormat("en-Us").format(parseInt(number));
	//return parseInt(no).toLocaleString("en-US");
};

const numberFormatM3 = function (number, decimal_place = 4) {
	if (number == null || number.toString().trim() == "" || isNaN(parseFloat(number))) {
		number = 0;
	}

	return new Intl.NumberFormat("en-Us", { minimumFractionDigits: decimal_place }).format(parseFloat(number));
};

const numberHandle = function (value) {
	if (value == null) {
		return parseFloat(0);
	}

	if (value != null && /,/.test(value)) {
		value = value.replaceAll(",", "");
	}

	return parseFloat(value);
};

const convertNestedObjectToNull = function (object) {
	//NOTE: MAXIMUM 3 LEVEL ONLY
	let objectFormatted = object;
	if (objectFormatted !== undefined && objectFormatted != null) {
		if (typeof objectFormatted === "object" && objectFormatted !== null && Object.keys(objectFormatted).length > 0) {
			Object.keys(objectFormatted).forEach(function (key) {
				if (objectFormatted[key] !== undefined && objectFormatted[key] != null) {
					if (typeof objectFormatted[key] === "object" && objectFormatted[key] !== null && Object.keys(objectFormatted[key]).length > 0) {
						//second level convert
						Object.keys(objectFormatted[key]).forEach(function (key2) {
							if (objectFormatted[key][key2] !== undefined && objectFormatted[key][key2] != null) {
								if (typeof objectFormatted[key][key2] === "object" && objectFormatted[key][key2] !== null && Object.keys(objectFormatted[key][key2]).length > 0) {
									//third level convert
									Object.keys(objectFormatted[key][key2]).forEach(function (key3) {
										objectFormatted[key][key2][key3] = null;
									});
								} else {
									objectFormatted[key][key2] = null;
								}
							}
						});
					} else {
						objectFormatted[key] = null;
					}
				}
			});
		} else {
			objectFormatted = null;
		}
	}

	return objectFormatted;
};

const getActualOffsetTopByElement = function (elementId, custom_option) {
	let default_option = {
		elem: "kt_header",
		top: 0,
		mobile_responsive: true,
		mobile_responsive_width: 992,
		mobile_elem: "kt_header_mobile",
		mobile_top: 0
	};

	if (custom_option === undefined) {
		custom_option = {};
	}
	let option = Object.assign({}, default_option, custom_option);
	let hiddenHeight = 0;

	if (option.elem) {
		let hiddenElement = document.querySelector("#" + option.elem);
		hiddenHeight = hiddenElement.offsetHeight;
	}

	if (option.top) {
		hiddenHeight += option.top;
	}

	if (option.mobile_responsive === true) {
		if (window.innerWidth < option.mobile_responsive_width) {
			if (option.mobile_elem) {
				let hiddenElement = document.querySelector("#" + option.mobile_elem);
				hiddenHeight = hiddenElement.offsetHeight;
			}

			if (option.mobile_top) {
				hiddenHeight += option.mobile_top;
			}
		}
	}

	var elem = document.querySelector("#" + elementId),
		offsetTop = 0;
	while (elem != null) {
		offsetTop += elem.offsetTop;
		elem = elem.offsetParent;
	}

	if (offsetTop >= hiddenHeight) {
		return offsetTop - hiddenHeight;
	} else {
		return offsetTop;
	}
};

const checkResetSelection = function (selection, current_value, field_name = "id") {
	let reset = true;
	for (let idx in selection) {
		if (selection[idx][field_name] == current_value) {
			reset = false;
			break;
		}
	}
	return reset;
};

const checkEmptyString = function (value, incl_zero = 0) {
	if (value == null || value.toString().trim() == "" || (incl_zero == 1 && parseFloat(value.toString()) == 0)) {
		return true;
	} else {
		return false;
	}
};

const checkEmptyObject = function (value) {
	if (typeof value == "undefined" || value == null) {
		return true;
	}

	if (typeof value == "object") {
		if (value == null || value.length == 0 || Object.entries(value).length == 0) {
			return true;
		} else {
			return false;
		}
	} else {
		return checkEmptyString(value);
	}
};

const markingDefaultSelect = function (markingSelection) {
	return markingSelection.length == 0 ? null : markingSelection[0].id;
};

const onFormatterPhoneNumber = function (value, calling_code = "+60") {
	if (calling_code == "+86") {
		return value.replace(/\D/g, "");
	} else {
		if (value.charAt(0) == "0") {
			value = value.slice(1);
		}
		return value.replace(/\D/g, "");
	}
};

const onFormatterCapitalAlphaNumeric = function (string) {
	return string.replace(/[^0-9a-zA-Z]+/gi, "").toUpperCase();
};

const onFormatterNumeric = function (string) {
	return string.replace(/[^0-9]+/gi, "");
};

const onPressPhoneNumber = function (evt) {
	evt = evt ? evt : window.event;
	var charCode = evt.which ? evt.which : evt.keyCode;
	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		evt.preventDefault();
	} else {
		return true;
	}
};

const nltobr = function (val) {
	if (val == null || val.toString().trim() == "") {
		return "";
	}

	return val.replaceAll(/\n/gi, "<br />");
};

const setRequiredErrorBorder = function (state, required, vuetify_style) {
	let required_border = required == 1 ? (vuetify_style == 0 ? `` : `required-border`) : ``;
	let error_border = vuetify_style == 0 ? `border border-danger` : `error-border`;
	return state != false ? required_border : error_border;
};

const viewPage = function (path) {
	return path.indexOf("view") !== -1 || path.indexOf("detail") !== -1 ? 1 : 0;
};

const newPage = function (path) {
	return path.indexOf("create") !== -1 || path.indexOf("copy") !== -1 ? 1 : 0;
};

const footer_props = {
	"items-per-page-options": [20, 50, 100, { text: i18n.t("COMMON.TXT_ALL"), value: -1 }],
	showCurrentPage: true
};

const datatable_options = {
	page: 1,
	itemsPerPage: 20,
	sortBy: [],
	multiSort: true
};

const onFormatterUpperCase = function (string) {
	if (string == null) return string;
	return string.toString().toUpperCase();
};

const epo_status_options = [
	{
		item: null,
		name: i18n.t("COMMON.TXT_ALL")
	},
	{
		item: 1,
		name: i18n.t("COMMON.ACTIVE_STATUS_VAL")
	},
	{
		item: 0,
		name: i18n.t("COMMON.SAVE_AS_DRAFT_STATUS_VAL")
	},
	{
		item: 2,
		name: i18n.t("COMMON.INACTIVE_STATUS_VAL")
	}
];

const createPage = function (path) {
	return path.indexOf("create") !== -1 || path.indexOf("copy") !== -1 ? 1 : 0;
};

const onlyNumber = function (event) {
	let keyCode = event.keyCode ? event.keyCode : event.which;
	if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
		// 46 is dot
		event.preventDefault();
	}
};

const onlyInteger = function (event) {
	let keyCode = event.keyCode ? event.keyCodS : event.which;
	if (keyCode < 48 || keyCode > 57) {
		// 46 is dot
		event.preventDefault();
	}
};

const copyPage = function (path) {
	return path.indexOf("copy") !== -1 || path.indexOf("copy-quote") !== -1 ? 1 : 0;
};

const statusBadge = function (status) {
	return status != null && status == 1 ? "success" : "danger";
};

const statusName = function (status, module = null) {
	return status == 1 ? i18n.t("COMMON.STATUS.ACTIVE") : module == "epo" ? i18n.t("EPO_MANAGEMENT.EPO_LISTING.TEXT_SAVE_AS_DRAFT") : i18n.t("COMMON.INACTIVE_STATUS_VAL");
};

const YesNoHandle = function (status) {
	return status == 1 ? i18n.t("COMMON.STATUS.YES") : i18n.t("COMMON.STATUS.NO");
};

const joinStr = function (data, separator = ", ") {
	return data.join(separator);
};

const requiredMsgHandle = function (field_name) {
	return i18n.t("COMMON.VALIDATION.REQUIRED", {
		name: field_name
	});
};

const placeHolderHandle = function (field_name) {
	return i18n.t("COMMON.PH_ENTER_TEXTBOX", {
		txt_fieldname: field_name
	});
};

const nullValueHandle = function (value, null_value = "-", display_val = null) {
	return checkEmptyString(value) ? null_value : checkEmptyString(display_val) ? value : display_val;
};

export default {
	selection_options,
	selection_all,
	selectOption,
	recordPerPage,
	UnixTimeToDateTime,
	UnixTimeToDate,
	getTodayDate,
	address_options,
	resetObjServer,
	selection_others,
	numberFormatCurrency,
	validateEmail,
	validateContactNo,
	validateCapitalAlphaNumeric,
	ucword,
	numberFormatInteger,
	numberFormatM3,
	numberHandle,
	convertNestedObjectToNull,
	getActualOffsetTopByElement,
	checkEmptyString,
	checkResetSelection,
	onFormatterPhoneNumber,
	onFormatterCapitalAlphaNumeric,
	onFormatterNumeric,
	onPressPhoneNumber,
	markingDefaultSelect,
	nltobr,
	setRequiredErrorBorder,
	viewPage,
	newPage,
	footer_props,
	datatable_options,
	epo_status_options,
	checkEmptyObject,
	createPage,
	onlyNumber,
	onlyInteger,
	copyPage,
	onFormatterUpperCase,
	statusBadge,
	statusName,
	joinStr,
	requiredMsgHandle,
	placeHolderHandle,
	YesNoHandle,
	nullValueHandle
};
