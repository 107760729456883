export const locale = {
	WEEKDAYS: {
		MONDAY: "Monday",
		TUESDAY: "Tuesday",
		WEDNESDAY: "Wednesday",
		THURSDAY: "Thursday",
		FRIDAY: "Friday",
		SATURDAY: "Saturday",
		SUNDAY: "Sunday",
	},
	COMMON: {
		LBL_AGENT: "GBizs Agent",
		LBL_MOBILE_CONTACT_NO: "Mobile Contact No.",
		LBL_MOBILE_CONTTACT_PIC: "Mobile Contact Pic",

		LBL_ALT_MOBILE_CONTACT_NO: "Mobile Contact No. (Alt)",
		LBL_ALT_MOBILE_CONTTACT_PIC: "Mobile Contact Pic (Alt)",

		LBL_OFFICE_NO: "Office No. ",
		LBL_OFFICE_NO_PIC: "Office No. PIC",
		LBL_SET_INVALID_PERSONNEL: "Invalid By",
		LBL_APPROVED_BY: "Approved By",
		LBL_REJECTED_BY: "Rejected By",
		LBL_REVERTED_BY: "Reverted By",
		LBL_ACKNOWLEDGE_BY: "Acknowledge By",
		LBL_VERIFY_BY: "Verify By",
		LBL_ACTION_TIME: "Action Time",
		LBL_REFERENCE_NO: "Ref. No.",
		LBL_HISTORY_DATE: "Modified Date",
		LBL_PARCEL_GUANRANTEE: "Parcel Guanrantee",

		LBL_FAX_NO: "Fax No.",
		LBL_FAX_NO_PIC: "Fax No. PIC",
		LBL_SERIAL_NO: "No.",

		TXT_ALL: "All",
		TOTAL_TITLE: "Total",
		TXT_RANGE: "Between {from} - {to}",
		TXT_ABV: ">= {from}",
		TXT_BELOW: "<= {to}",

		SELECTION_OTHERS: "Others",
		PH_ENTER_TEXTBOX: "Enter {txt_fieldname}",
		PH_ENTER_DATE: "Enter Date",
		PH_ENTER_DATE_FROM: "Enter {name} From",
		PH_ENTER_DATE_TO: "Enter {name} To",
		MSG_SUPPORT_IMAGE_SIZE_FORMAT:
			"Supported size should be less than 1 MB and Supported Format .jpg, .jpeg, .png",
		MSG_IMAGE_FORMAT_NOT_SUPPORTED:
			"Image format not supported. Supported format: .jpg, .jpeg, .png",
		MSG_SUPPORT_DOC_SIZE_FORMAT:
			"Supported Document size should be less than 10 MB",
		MSG_PROGRESS: "Please wait a moment ... ....",
		MSG_SUPPORT_EXCEL_FILE: "Supported File Type: .xlsx, .xls",
		MSG_ROW_ERROR: "Row: {idx}",

		STATUS: {
			INACTIVE: "Inactive",
			ACTIVE: "Active",
			SUSPEND: "Suspend",
			UNVERIFIED: "Un-verify",
			YES: "Yes",
			WGI: "WGI",
			NO: "No",
			DRAFT: "Save As Draft",
			PENDING: "Pending",
			APPROVED: "Approved",
			REJECTED: "Rejected",
			VERIFY: "Verifying",
			CLOSED: "Closed",
			OPEN: "Open",
			ACKNOWLEDGE: "Acknowledge",
			NEW: "New",
			EXPIRE: "Expired",
			VALID: "Valid",
			LOCKED: "Locked"
		},


		MSG: {
			API_SERVER_DOWN: "Server Connection Refused. Please contact System Admin.",
			MSG_DATA_NOT_FOUND: "No Records Found",
			DATA_NOT_AVAILABLE: "No Data Available",
			SUCCESSFUL_IMPORT_EXCEL: "Import excel successfully"
		},

		VALIDATION: {
			INVALID: "{name} is not valid",
			REQUIRED: "{name} is required",
			REQUIRED_GREATER_ZERO: "{name} must be greater than zero",
			MIN_LENGTH: "{name} minimum length is {min}",
			AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
			NOT_FOUND: "The requested {name} is not found",
			REF_NOT_FOUND: "Ref/Doc No.: {ref_no} is not found",
			INVALID_LOGIN: "The login detail is incorrect",
			REQUIRED_FIELD: "Required field",
			MIN_LENGTH_FIELD: "Minimum field length:",
			MAX_LENGTH_FIELD: "Maximum field length:",
			INVALID_FIELD: "Field is not valid",
			NUMBER: "{name} must be number",
			CANNOT_EQUAL: "{p1} cannot be the same as {p2}",
			EXPIRE: "{name} must be greater than current date",

			MISSING_REQ_DATA: "Please fill in all details",
			INVALID_WARNING_MSG: "Please, provide correct data!",
			DUPLICATE_ENTRY: "{name} is duplicate. Please Try Again",
			UNIQUE_FIELD: "{name} must be unique.",
			DOCUMENT_NUMBERING_NOT_SET: "Document Number Format is not found. Please setup first.",
			DOC_NUMBER_NOT_SET_TYPE: "{doc_type} Document Number Format is not found. Please setup first.",
			ID_NOT_FOUND: "Record not found",
			MISSING_DOC_TYPE: "Doc Type: {doc_type} not found. Please Contact System Admin.",
			UNIQUE_VALUE: "{code} is not available",
			STATE_CODE_REQUIRED: "State code is not set",
			MARKING_NOT_EXISTS: "Marking not exists",
			DOCUMENT_NO_DUPLICATE: "Document Number is duplicate",
			DATE_FROM_GREATER_THAN_DATE_TO: "{date_to} must be greater than {date_from}",
			DATA_NOT_FOUND_REQ_CREATE: "{code} unable to found. Please create a new record",

			INVALID_CONTACT_NO_FORMAT: {
				FORMAT_MY: "{name} is not valid, correct format eg: 123456789"
			},
			POSITIVE: "{name} only allow enter positive number",
			RECORD_NO_SELECTED: "No record has been selected",
			INVALID_DATE_FORMAT_EXCEL: "{name} date format not supported",
			ROW_DATA_NOT_FOUND: "Row: {idx} data not found",
			DUPLICATE_RECORD_SET: "Duplicate Set",
			DOCUMENT_FORMAT_LENGHT: "No. Format cannot more than {len} character",
			DOCUMNET_NUMBER_FORMAT_DUPLICATE_SET: "No.Format: {format} duplicate set",
			EXCEL_FILE_NOT_UPLOAD: "Please upload product excel file",
			EXCEL_FILE_TYPE_INVALID: "Excel File Type Invalid",
			EXCEL_IMPORT_DATA_NOT_FOUND: "Uploaded Data not found",
			EXCEL_DATA_INCORRECT: "Please ensure uploaded data is correct",
		},

		BTN_DOWNLOAD_ALL_AS_PDF: "Download All As PDF",
		BTN_DOWNLOAD_AS_PDF: "Download As PDF",
		BTN_REJECT: "Reject",
		BTN_VERIFY: "Verify",
		BTN_SEARCH: "Search",
		BTN_CLEAR: "Clear",
		BTN_CANCEL: "Cancel",
		BTN_BACK: "Back",
		BTN_SAVE: "Save",
		BTN_BROWSE: "Browse",
		BTN_EDIT: "Edit",
		BTN_REVERT: "Revert",
		BTN_CREATE: "Create New {btn_name}",
		BTN_SUBMIT: "Submit",
		BTN_APPROVE: "Approve",
		BTN_ADD: "Add",
		BTN_DELETE: "Delete",
		BTN_COPY: "Copy",
		BTN_CLOSE: "Close",
		BTN_CONFIRM: "Confirm",
		BTN_ACKNOWLEDGE: "Acknowledge",
		BTN_PREVIEW: "Preview",
		BTN_SAVE_AS_DRAFT: "Save As Draft",
		BTN_PRINT: "Print",
		BTN_PRINT_ALL: "Print All",
		BTN_CONFIRM_N_APPROVE: "Confirm & Approve",
		BTN_VIEW: "View",
		BTN_RESET: "Reset",
		BTN_DOWNLOAD_ALL: "Download All",
		BTN_DOWNLOAD_SELECTED: "Download Selected",
		BTN_COST_SETUP: "Cost Setup",
		BTN_LEAVE: "Leave Page",
		BTN_STAY: "Stay on the page",
		BTN_REFRESH: "Refresh",
		BTN_OK: "Ok",
		BTN_UPLOAD: "upload",
		BTN_IMPORT: "Import Excel",
		BTN_POST: "Post",
		BTN_REVERSE: "Reverse",
		BTN_SYNC_ALL: "Sync All",
		BTN_SYNC_ALL_WAREHOUSE: "Sync All To Warehouse",
		BTN_SYNC_ALL_AGENT: "Sync All To Agent",
		BTN_PREVIEW_IMAGE: "Preview Image",
		BTN_PREVIEW_DOC: "Preview Document",
		BTN_UPLOAD_IMAGE: "Upload Image",
		BTN_UPLOAD_DOC: "Upload Supported Document",
		BTN_ALL: "All",
		BTN_HISTORY: "History",
		BTN_SYNC_TO_AGENT: "Sync To Agent",
		BTN_SYNC_TO_WAREHOUSE: "Sync To Warehouse",
		BTN_NEW: "New",
		BTN_SKIP: "Skip",
		BTN_ENABLE_EDIT: "Enable Edit",
		BTN_DISABLE_EDIT: "Disable Edit",
		BTN_COPY_EDIT: "Copy/Edit",
		BTN_IGNORE: "Ignore",
		BTN_SELECT: "Select",
		BTN_LINK: "Link",
		BTN_REMOVE: "Remove",
		BTN_DROP_FILE: "Click or Drag {file} file to this area to upload",
		BTN_CLICK_HERE: "Click Here",

		FILE_TYPE: {
			EXCEL: "Excel"
		}
	},
	AUTH: {
		BTN_SIGN_OUT: "Sign Out",
		USERNAME_TITLE: "Hi, {username}",
	},
	EPO: {
		FORMAT_CN: "Chinese Format",
		FORMAT_EN: "English Format",
		FORMAT_IN: "Indonesian Format",
		FORMAT_TH: "Thai Format",
		FORMAT_VN: "Vietnamese Format",

		DOWNLOAD_EPO_IMPORT_EXCEL_FORMAT: "Download Import Product Excel Format",
		BTN_IMPORT_PROD: "Import Product",
		UPLOAD_RPODUCT_TITLE: "Import Product Excel File",

		MSG: {
			EXCEL_SHEET_NOT_FOUND: "{name} Sheet not found in excel file",
			EXCEL_FORMAT_NOT_FOUND: "{name} Sheet Column not matching!",
		}
	},
	CUSTOMER: {
		REQUEST_TYPE: {
			CREATE_TYPE: "Create New",
			UPDATE_TYPE: "Update",
		},
		STATUS: {
			PENDING: "Pending",
			VERIFYING: "Verifying",
			APPROVED: "Approved",
			REJECTED: "Rejected",
		},
		LBL_SAVE_DELIVERY_ADDRESS: "Save As Delivery Address",
	},
	TT: {
		BENE_MODEL: {
			BENE_TITLE: "Beneficiary Details",
			INTERMEDIATE_TITLE: "Intermediate Bank Details",
			INTERMEDIATE_CHECKBOX: "Intermediate Bank?",
			ACC_HOLDER: "Account Holder",
			ACC_NO: "Account Number",
			BENE_CONTACT_PIC: "Bene Contact PIC",
			BENE_CONTACT_CALLING_CODE: "Bene Calling Code",
			BENE_CONTACT_NO: "Bene Contact Number",
			RI_NO: "Ri Number",
			BANK_NAME: "Bank Name",
			BANK_CITY: "Bank City",
			BANK_COUNTRY: "Bank Country",
			BANK_ADDRESS: "Bank Address",
			BENE_ADDRESS: "Bene Address",
			BANK_REMARK: "Bank Remark",
			BANK_CODE: "BIC / Swift Code",
			IBAN: "IBAN",
			INTER_BANK_NAME: "Intermediate Bank Name",
			INTER_BANK_ACC: "Intermediate Account No",
			INTER_SWIFT_CODE: "Intermediate BIC / Swift Code",
			INTER_IBAN_CODE: "Intermediate IBAN Code",
			BENE_DETAIL_NAME: "Beneficiary Detail Name"
		},
	},
	GRN_FORMAT: {
		HEADER: {
			DATE: "Date",
			SUPPLIER: "Supplier",
			SUPPLIER_CODE: "Supplier Code",
			REMARK: "Remark",
			MOBILE: "Mobile No.",
			CONTACT_PERSON: "Contact Person",
			DOC_NO: "Doc No.",
			PHONE_NO: "Phone No.",
			PAYMENT_METHOD: "Payment Method",
			RECEIPT: "Receipt"
		},
		CONTENT: {
			SERIAL_NO: "No.",
			CUSTOMER_MARKING: "Custmer Marking",
			SO_NO: "So No.",
			CTN: "Quantity",
			WEIGHT: "Weight",
			DIMENSION: "Dimension",
			OTHER: "Other",
			PN: "PN",
			TOTAL: "Total",
		},
		FOOTER: {
			DELIVER: "Delivery Person's Signature",
			PERSON_IN_CHARGES: "Person In Charge",
			CONTACT_PERSON: "Contact Person",
			CONTACT_NO: "Contact No."
		}
	},
	PN_FORMAT: {
		HEADER: {
			DATE: "Date",
			SUPPLIER: "Supplier",
			SUPPLIER_CODE: "Supplier Code",
			REMARK: "Remark",
			MOBILE: "Mobile No.",
			CONTACT_PERSON: "Contact Person",
			DOC_NO: "Doc No.",
			PHONE_NO: "Phone No.",
			PAYMENT_METHOD: "Payment Method",
			ACCOUNT_NAME: "Account Name",
			ACCOUNT_NO: "Account No.",
			BANK_ADDRESS: "Bank Branch",
			BANK_NAME: "Bank Name"
		},
		CONTENT: {
			SERIAL_NO: "No.",
			CUSTOMER_MARKING: "Custmer Marking",
			SO_NO: "So No.",
			CTN: "Quantity",
			CURRENCY: "Currency",
			WEIGHT: "Weight",
			DIMENSION: "Dimension",

			TOTAL_AMT: "Order Amount",
			DISCOUNT: "Discount",
			DEPOSIT: "Deposit",
			STICKER_FEE: "Deduct Sticker Fee",
			BALANCE_AMT: "BALANCE AMOUNT",
			TOTAL: "Total"
		},
		FOOTER: {
			DELIVER: "Delivery Person's Signature",
			PERSON_IN_CHARGES: "Person In Charge",
			CONTACT_PERSON: "Contact Person",
			CONTACT_NO: "Contact No."
		}
	},
	REPORT: {
		PACKING_LIST_FORMAT: {
			TITLE: "Packing List Details (C) New",
			SO_NO: "S/O No.",
			SUPPLIER_NAME: "Sup Name",
			T_M3: "T/M3<br />(T/CM3)",
			T_KG: "T/Kg<br />(Vol Weight)",
			T_QTY: "T/Pqty",
			BILL_AMT: "Bill Amt",
			LOADED_AMOUNT: "Loaded Amt",
			CUS_DEPOSIT: "Cus Deposit",
			MY_PAYMENT: "My P'ment",
			FCP_AMT: "FCP Amount",
			SUP_DEPOSIT: "Sup Deposit",
			LOCAL_PAYMENT: "Local P'ment",

			STOCK_DESC: "Stock Short Desc",
			M3: "M3",
			KG: "Kg",
			P_QTY: "P Qty",
			QUANTITY: "Quantity",
			UOM: "Uom",
			UNIT_PRICE: "Unit Price",
			AMOUNT: "Amount",
			RECEIVE_DATE: "Received Date",
			REF_ID: "Ref ID",
			MAIN_SALESMAN: "Main Salesman",
			SALESMAN: "Salesman",
			CUSTOMER_MARKING: "Marking",
			DELIVERY_ID: "Delivery Id",

			REPORT_FILTER: "Report Filter",
			SHOW_M3: "Show M3?",
			SHOW_KG: "Show Kg?",
			SHOW_BILL_AMOUNT: "Show Bill Amount?",
			SHOW_STOCK_DESC1: "Show Stock Desc 1?",
			SHOW_STOCK_DESC2: "Show Stock Desc 2?",
			SHOW_SUPPLIER: "Show Supplier",
			YES: "Yes",
			NO: "No",
			CURRENCY_ID: "Currency ID",
			REMARK: "Remark",
			DEPOSIT_DATE: "D. Date",
			DEPOSIT_NO: "PN #",
			DEPOSIT_RATE: "TR",
			DEPOSIT_AMOUNT: "TS Amt",
			DEPOSIT_CHARGE: "FTC",
			DEPOSIT_CHARGE_MYR: "TC (RM)",
			DEPOSIT_IR: "IR %",
			DEPOSIT_OR: "AOR No.",

			SUB_TTL_T_M3: "T/M3",
			SUB_TTL_T_KG: "T/Kg",
			SUB_TTL_T_PQTY: "T/Pqty",
			SUB_TTL_REF: "Ref ",
			SUB_TTL_VOL_WEIGHT: "Vol Weight",
			SUBTOTAL: "SubTotal",
			CURRENCY: "Currency"
		},
		STOCK_BALANCE_FORMAT: {
			TITLE: "Stock Balance Report with Payment Info",
			DETAIL: "Detail",
			SUMMARY: "Summary",
			SERIAL_NO: "S/N",
			CURRENCY_ID: "Cur ID",
			SO_NO: "S/O No.",
			RECEIVE_DATE: "Rec Date",
			NOTICE_ID: "Notice ID",
			TOTAL_M3: "Total M3",
			TOTAL_PACK: "Total Pack",
			BAL_AMT: "Balance Amount",
			CUS_DEPOSIT: "Cust Deposit",
			MY_PAYMENT: "My Payment",
			SUP_DEPOSIT: "Sup Deposit",
			CASH_PAYMENT: "Cash P'ment",
			CREDIT_PAYMENT: "Credit P'ment",
			FCP_AMOUNT: "FCP Amount",
			PAYABLE_AMT: "Payable Amt",

			PO_DATE: "PO Date",
			STOCK_SHORT: "Stock Short",
			STOCK_DESC: "Stock Desc",
			M3: "M3",
			BAL_PACK: "Bal Pack",
			QUANTITY: "Quantity",
			PURCHASE_PRICE: "Purchase Price",
			AMOUNT: "Amount",
			MAIN_SALESMAN: "Main Salesman",
			SALESMAN: "Salesman",
			CUSTOMER_MARKING: "Marking",
			DELIVERY_ID: "Delivery Id",
			OR_DATE: "O/R Date",
			LOADED_DATE: "Loaded Date",
			// REPORT_FILTER: "All / KCG Only / Exclude KCG",
			FROM: "From",
			TO: "To",
			REPORT_FILTER: "Report Filter",
			REPORT_OPTIONS: "Report Options",
			DELIVERY_ID_TOTAL: "Delivery ID Total",
			MARKING_TOTAL: "Marking Total",
			SALESMAN_TOTAL: "Salesman Total",
			MAIN_SALESMAN_TOTAL: "Main Salesman Total",
			CURRENCY_TOTAL: "Currency Total"
		},
		DO_FORMAT: {
			FORMAT_TITLE: "DELIVERY ORDER",
			TEL_NO: "Tel No.",
			GOODS_RECEIVE_CONDITION: "Goods received in good order & condition",
			SIGNATURE_TITLE: "Authorised Signature & Co Stamp",
			FOOTER_E_DESC: "Computer Generated, no signature required",
			FOOTER_END: "== End ==",

			DONO: "D/Order #",
			REF_ID: "Yr Ref",
			DO_DATE: "Date",
			TRANPORTER: "T-Ref",
			PAGE: "Page",
			MOBILE_NO: "Mobile No.",
			FORWARDER: "Forwarder",
			FAX: "Fax",
			REMARK1: "Remark1",
			REMARK2: "Remark2",
			SERIAL_NO: "S/N",
			SO_NO: "",
			DESCRIPTION: "Description",
			QUANTITY: "Quantity",
			UNIT: "Unit",
			PKG: "Pkg",
			QTY: "Qty",
			TOTAL_M3: "Total M3",
			TOTAL_KG: "Total KG",
			TOTAL_PACKING: "Total Packing",
		}
	}
};
