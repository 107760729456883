import ApiService from "@/core/services/api.service";
import i18n from "@/core/plugins/vue-i18n";

const getCountries = async function (data = {}) {
	let response = await ApiService.post("countries/selection", data);
	if (response.data.code == 200 && response.data.result.length > 0) {
		return response.data.result;
	} else {
		return [];
	}
};

const getPopularCountries = async function (data = {}) {
	let result = [];
	let response = await ApiService.post("countries/popular-selection", data);
	if (response.data.code == 200 && response.data.result.length > 0) {
		if (Object.entries(data).length > 0) {
			let rdata = response.data.result;
			for (let idx in rdata) {
				let cresult = JSON.parse(rdata[idx].cresult);
				let cheader = cresult.find((rdata) => typeof rdata.header != "undefined");
				cresult[cresult.indexOf(cheader)].header = cheader.header == "POPULAR" ? i18n.t("COMMON.POPULAR_TITLE") : i18n.t("COMMON.SELECTION_ALL_TITLE");
				result = result.concat(cresult);
			}
		} else {
			result = response.data.result;
		}
	}
	return result;
};

const getCallingCode = function () {
	return ApiService.post("calling-code/selection")
		.then((response) => {
			if (response.status == 200 && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

const getStates = function (country_id) {
	return ApiService.post("states/selection", { country_id: country_id })
		.then((response) => {
			if (response.status == 200 && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

const getCities = function (country_id, state_id) {
	return ApiService.post("city/selection", { country_id: country_id, state_id: state_id })
		.then((response) => {
			if (response.status == 200 && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

const getPostcodes = function (country_id, state_id, city_id) {
	return ApiService.post("postcodes/selection", { country_id: country_id, state_id: state_id, city_id: city_id })
		.then((response) => {
			if (response.status == 200 && response.data.result.length > 0) {
				return response.data.result;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};

export default {
	getCountries,
	getCallingCode,
	getStates,
	getCities,
	getPostcodes,
	getPopularCountries
};
