import Vue from "vue";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import Common from "@/core/services/common";
import CoreJs from "@/core/services/store/coreApi";
import RegionJs from "@/core/services/store/region.js";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { GET_TEMPLATE_LAYOUT_SETTINGS, GET_LOCALE, GET_SYSTEM_SETTINGS } from "@/core/services/store/layoutconfig.module";
import SelectField from "@shared/src/components/SelectionField.vue";
import CoreSettingsJs from "@shared/src/core/services/settings.js";
import VModal from "vue-js-modal";
import JsonViewer from 'vue-json-viewer';
import CardBox from "@/view/content/Card.vue";

Vue.config.productionTip = false;

import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "@shared/src/assets/css/gbizsUtilities.css";
import Swal from "sweetalert2";
Vue.component("selectField", SelectField);
Vue.use(Antd);
Vue.use(VModal);
Vue.use(JsonViewer);

// API service init
ApiService.init();
Vue.prototype.$enviroment = process.env.NODE_ENV;
Vue.prototype.$ApiService = ApiService;
Vue.prototype.$Common = Common;
Vue.prototype.$CoreJs = CoreJs;
Vue.prototype.$Swal = Swal;
Vue.prototype.$RegionJs = RegionJs;
Vue.prototype.$CoreSettingsJs = CoreSettingsJs;

Vue.component("card-box", CardBox);

// Remove this to disable mock API
// MockService.init();
router.beforeEach((to, from, next) => {
	document.title = to.meta.title || "GBIZS System";
	// Ensure we checked auth before each page load.
	let requiresAuth = true;
	if (to.meta.hasOwnProperty("requiresAuth")) {
		requiresAuth = to.meta.requiresAuth;
	}

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);

	let middleware = [store.dispatch(GET_TEMPLATE_LAYOUT_SETTINGS), store.dispatch(GET_LOCALE), store.dispatch(GET_SYSTEM_SETTINGS), store.dispatch(VERIFY_AUTH)];

	if (requiresAuth === true && !store.getters.isAuthenticated) {
		Swal.fire({
			icon: "error",
			text: i18n.t("AUTH.LOGIN.LOGIN_REQUIRED")
		});

		router.push({ path: "login" });
	} else {
		//middleware = [store.dispatch(GET_LOCALE), store.dispatch(GET_TEMPLATE_LAYOUT_SETTINGS), store.dispatch(VERIFY_AUTH), store.dispatch(GET_SYSTEM_SETTINGS)];
		// Ensure we checked auth before each page load.
		Promise.all(middleware)
			.then(next)
			.catch(() => {
				router.push({ name: "home" });
			});
		// reset config to initial state
		store.dispatch(RESET_LAYOUT_CONFIG);

		// Scroll page to top on every route change
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 100);
	}
});

new Vue({
	router,
	store,
	i18n,
	vuetify,
	render: (h) => h(App)
}).$mount("#app");
