const i18nService = {
	defaultLanguage: "en",
	defaultLocaleId: 1,

	languages: [
		{
			id: 1,
			lang: "en",
			name: "English",
			flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg"
		},
		{
			id: 2,
			lang: "ch",
			name: "Mandarin",
			flag: process.env.BASE_URL + "media/svg/flags/034-china.svg"
		},
		// {
		// 	lang: "es",
		// 	name: "Spanish",
		// 	flag: process.env.BASE_URL + "media/svg/flags/128-spain.svg"
		// },
		// {
		// 	lang: "jp",
		// 	name: "Japanese",
		// 	flag: process.env.BASE_URL + "media/svg/flags/063-japan.svg"
		// },
		// {
		// 	lang: "de",
		// 	name: "German",
		// 	flag: process.env.BASE_URL + "media/svg/flags/162-germany.svg"
		// },
		// {
		// 	lang: "fr",
		// 	name: "French",
		// 	flag: process.env.BASE_URL + "media/svg/flags/195-france.svg"
		// }
	],

	/**
	 * Keep the active language in the localStorage
	 * @param lang
	 */
	setActiveLanguage(lang) {
		localStorage.setItem("language", lang);
	},

	setActiveLocaleId(locale_id){
		localStorage.setItem("locale_id", locale_id);
	},

	/**
	 * Get the current active language
	 * @returns {string | string}
	 */
	getActiveLanguage() {
		return localStorage.getItem("language") || this.defaultLanguage;
	},

	/**
	 * Get the current active language
	 * @returns {string | string}
	 */
	getActiveLocaleId() {
		if(localStorage.getItem("locale_id") == "null"){
			localStorage.removeItem("locale_id");
		}

		return localStorage.getItem("locale_id") || this.defaultLocaleId;
	}
};

export default i18nService;
