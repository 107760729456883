import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE_URL}`;
	},

	getFullURL(urlpath) {
		return Vue.axios.defaults.baseURL + urlpath;
	},

	getJwtToken() {
		return `Bearer ${JwtService.getToken()}`;
	},

	/**
	 * Set the default HTTP request headers
	 */
	setHeader() {
		if (JwtService.getToken()) {
			Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
		}
	},
	unsetHeader() {
		if (JwtService.getToken()) {
			delete Vue.axios.defaults.headers.common["Authorization"];
		}
	},
	setLanguage() {
		Vue.axios.defaults.headers.common["Language"] = localStorage.getItem("language") || this.defaultLanguage;
	},

	query(resource, params) {
		return Vue.axios.get(resource, params).catch((error) => {
			// console.log(error);
			throw new Error(`[KT] ApiService ${error}`);
		});
	},

	/**
	 * Send the GET HTTP request
	 * @param resource
	 * @param slug
	 * @returns {*}
	 */
	get(resource, slug = "") {
		return Vue.axios.get(`${resource}/${slug}`);
	},

	/**
	 * Set the POST HTTP request
	 * @param resource
	 * @param params
	 * @returns {*}
	 */
	post(resource, params, without_auth) {
		if (without_auth) {
			delete Vue.axios.defaults.headers.common["Authorization"];
		} else {
			Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
		}

		return Vue.axios.post(`${resource}`, params);
	},

	/**
	 * Send the UPDATE HTTP request
	 * @param resource
	 * @param slug
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	update(resource, slug, params) {
		return Vue.axios.put(`${resource}/${slug}`, params);
	},

	/**
	 * Send the PUT HTTP request
	 * @param resource
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	put(resource, params) {
		return Vue.axios.put(`${resource}`, params);
	},

	/**
	 * Send the DELETE HTTP request
	 * @param resource
	 * @returns {*}
	 */
	delete(resource) {
		return Vue.axios.delete(resource).catch((error) => {
			// console.log(error);
			throw new Error(`[RWV] ApiService ${error}`);
		});
	},
	uploadfile(resource, params, headers, without_auth) {
		if (without_auth) {
			delete Vue.axios.defaults.headers.common["Authorization"];
		} else {
			Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
		}

		return Vue.axios.post(`${resource}`, params, headers);
	}
};

export default ApiService;
