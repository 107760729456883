import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";

//action type
export const GET_TEMPLATE_LAYOUT_SETTINGS = "getTemplateLayoutSettings";
export const GET_LOCALE = "locale";
export const GET_SYSTEM_SETTINGS = "getSystemSettings";

//mutation types
export const SET_TEMPLATE_LAYOUT_SETTINGS = "setTemplateLayoutSettings";
export const SET_LOCALE = "setLocale";
export const SET_SYSTEM_SETTINGS = "setSystemSettings";

const state = {
	template_layout_settings: {
		header_menu: [],
		footer_menu: [],
		dashboard_menu: []
	},
	locales: [],
	systemSettings: []
};

const getters = {
	getTemplateLayoutHeaderMenu(state) {
		return state.template_layout_settings.header_menu;
	},
	getTemplateLayoutFooterMenu(state) {
		return state.template_layout_settings.footer_menu;
	},
	getTemplateLayoutDashboardMenu(state) {
		return state.template_layout_settings.dashboard_menu;
	},
	currentTemplateLayoutSettings(state) {
		return state.template_layout_settings;
	},
	currentLocale(state) {
		return state.locales;
	},
	currentActivateLocale() {
		return i18nService.getActiveLocaleId();
	},
	currentSystemSettings(state) {
		return state.systemSettings;
	}
};

const actions = {
	[GET_TEMPLATE_LAYOUT_SETTINGS](context) {
		return new Promise((resolve) => {
			let tokenExisted = window.localStorage.getItem("id_token") == null ? true : false;
			ApiService.post("template-layout/settings", { language_id: i18nService.getActiveLocaleId() }, tokenExisted).then((response) => {
				let data = response.data;
				if (data.code == 200) {
					context.commit(SET_TEMPLATE_LAYOUT_SETTINGS, data.result);
					resolve(data);
				}
			});
		});
	},
	[GET_LOCALE](context) {
		return new Promise((resolve) => {
			ApiService.post("locale/list", {}, true).then((response) => {
				let data = response.data;
				if (data.code == 200) {
					/*for(let idx in data.result){
						if(data.result[idx].flag != null){
							data.result[idx].flag = process.env.BASE_URL + data.result[idx].flag;
						}
					}*/
					context.commit(SET_LOCALE, data.result);
					resolve(data);
				}
			});
		});
	},
	[GET_SYSTEM_SETTINGS](context) {
		return new Promise((resolve) => {
			ApiService.get("settings/sys-setting").then((response) => {
				let data = response.data;
				if (data.code == 200) {
					let newData = data.result;
					// let result = data.result;
					// let newData = {};
					// for (let group in result) {
					// 	if (group == "defaultCurrency") {
					// 		newData[group] = result[group];
					// 		continue;
					// 	} else {
					// 		newData[group] = {};
					// 	}

					// 	for (let key in result[group]) {
					// 		let record = result[group][key];
					// 		if (key == "defaultCurrency") {
					// 			newData[group][key] = record;
					// 		} else if (record["serialized"] != null && record["serialized"] == "2") {
					// 			newData[group][key] = JSON.parse(record.value);
					// 		} else {
					// 			newData[group][key] = record.value;
					// 		}
					// 	}
					// }
					context.commit(SET_SYSTEM_SETTINGS, newData);
					resolve(data);
				}
			});
		});
	}
};

const mutations = {
	[SET_TEMPLATE_LAYOUT_SETTINGS](state, settings) {
		state.template_layout_settings = settings;
	},
	[SET_LOCALE](state, localeList) {
		state.locales = localeList;
	},
	[SET_SYSTEM_SETTINGS](state, settings) {
		state.systemSettings = settings;
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
